import React from "react"
import { Button, Form, FormControl, Row, Col } from "react-bootstrap"
import { Controller, Control, useFormContext } from "react-hook-form"
import { Editor } from "@tinymce/tinymce-react"
import { Edit } from "react-feather"
import { Texte_t } from "../syllabus/syllabus-types"
import { Bilingue_t } from "./bilingue"
import { useSanctum } from "../sanctum/sanctum"

const mceInit = {
	plugins: `autolink autosave charmap code fullscreen help 
		insertdatetime link lists nonbreaking save 
		searchreplace table visualblocks visualchars wordcount`,
	menubar: "file edit view insert format tools table help",
	toolbar: `undo redo | bold italic underline strikethrough | 
		formatselect | alignleft aligncenter 
		alignright alignjustify | outdent indent | numlist bullist | 
		forecolor backcolor removeformat | charmap code | 
		fullscreen save print | insertfile link`,
	menu: {
		file: { title: "File", items: "preview | export print" },
		edit: {
			title: "Edit",
			items: "undo redo | cut copy paste pastetext | selectall | searchreplace"
		},
		view: {
			title: "View",
			items: "code | visualaid visualchars visualblocks | spellchecker | preview fullscreen | showcomments"
		},
		insert: {
			title: "Insert",
			items: "image link media addcomment pageembed template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor tableofcontents | insertdatetime"
		},
		format: {
			title: "Format",
			items: "bold italic underline strikethrough superscript subscript codeformat | styles blocks align | language | removeformat"
		},
		tools: {
			title: "Tools",
			items: "spellchecker spellcheckerlanguage | a11ycheck code wordcount"
		},
		table: {
			title: "Table",
			items: "inserttable | cell row column | advtablesort | tableprops deletetable"
		},
		help: { title: "Help", items: "help" }
	}
}

interface PropsHtmlEditorBilingue {
	name: "fr" | "en"
	control: Control<Bilingue_t>
}

interface PropsHtmlEditorSimple {
	name: "texte"
	control: Control<Texte_t>
}

export const HtmlEditorBilingue = ({
	name,
	control
}: PropsHtmlEditorBilingue): JSX.Element => (
	<Controller
		name={name}
		control={control}
		render={({ field: { onChange, value, name } }) => (
			<Editor
				value={value}
				textareaName={name}
				onEditorChange={onChange}
				init={mceInit}
				apiKey="np5hwwjj180sg03xvx98qj7n7vwgtgih83r9xodr95d6sqax"
			/>
		)}
	/>
)

export const HtmlEditorText = ({
	name,
	control
}: PropsHtmlEditorSimple): JSX.Element => (
	<Controller
		name={name}
		control={control}
		render={({ field: { onChange, value, name } }) => (
			<Editor
				value={value}
				textareaName={name}
				onEditorChange={onChange}
				init={mceInit}
				apiKey="np5hwwjj180sg03xvx98qj7n7vwgtgih83r9xodr95d6sqax"
			/>
		)}
	/>
)

interface PropsOkCancel {
	cancel: () => void
	valid?: () => void
}

export const OkCancel = ({ cancel, valid }: PropsOkCancel): JSX.Element => (
	<>
		<Button
			variant="success"
			onClick={valid}
			type={valid ? "button" : "submit"}
			size="sm"
		>
			Valider
		</Button>{" "}
		<Button variant="danger" size="sm" onClick={cancel}>
			Annuler
		</Button>
	</>
)

interface PropsEditButton {
	onClick: () => void
}

export const EditButton = ({
	onClick
}: PropsEditButton): JSX.Element | null => {
	const {
		authState: { user }
	} = useSanctum()
	return user ? (
		<Button
			name="edit"
			variant="outline-primary"
			size="sm"
			className="col-auto me-2"
			onClick={onClick}
		>
			<Edit color="purple" size={18} />
		</Button>
	) : null
}

interface PropsFormLibelle {
	nom: string
	labSz?: number
	label: string
	number?: boolean
	contrainte?: {
		[ix: string]:
			| boolean
			| number
			| string
			| (() => boolean)
			| { [ixx: string]: () => boolean }
	}
	invalide?: boolean
	feedback?: string
	children: React.ReactNode
}

export const FormLibelle = ({
	nom,
	labSz,
	label,
	number,
	contrainte,
	invalide,
	feedback,
	children
}: PropsFormLibelle): JSX.Element => {
	const { register } = useFormContext()
	labSz = labSz ?? 4
	return (
		<Form.Group as={Row} controlId={`form_${nom}`}>
			<Form.Label column sm={labSz} className="text-primary">
				{label}
			</Form.Label>
			<Col sm={labSz !== 12 ? 12 - labSz : 12}>
				<Form.Control
					as="input"
					type={number ? "number" : "text"}
					{...register(nom, contrainte)}
					isInvalid={!!invalide}
				/>
				{feedback && contrainte && (
					<FormControl.Feedback type="invalid">
						{feedback}
					</FormControl.Feedback>
				)}
			</Col>
			{children && (
				<Col className="lh-sm mb-2">
					<Form.Text className="text-secondary">{children}</Form.Text>
				</Col>
			)}
		</Form.Group>
	)
}
