import React, { useCallback } from "react"
import { useState, useEffect, createContext } from "react"
import { Container } from "react-bootstrap"
import { useParams } from "react-router-dom"
import { useSanctum } from "../sanctum/sanctum"
import { ElpFlat } from "./elp-flat"
import { LigneBilingueFlat } from "./bilingue-flat"
import {
	MapModEvalComp_t,
	MapNiveauxComp_t
} from "../syllabus/syllabus-types"
import {
	DiplomeFlat_t, EcoleFlat_t, FormationFlat_t,
	MapBlocsComp_t, ParcoursFlat_t
} from "./api-syllabus-arbre"

/**
 * FormationContextFlat_t
 *
 * Contexte de formation: description du parcours
 */
export interface FormationContextFlat_t {
	annee?: number
	diplome?: DiplomeFlat_t
	parcours?: ParcoursFlat_t
	langue: "fr" | "en"
	blocsCompetence?: MapBlocsComp_t
	niveauxCompetence?: MapNiveauxComp_t
	modalitesEvalCompetence?: MapModEvalComp_t
}

interface Props {
	langue: "fr" | "en"
}

export const ContexteFormationFlat = createContext<FormationContextFlat_t>({
	langue: "fr"
})

export const Imprimable = ({ langue }: Props): JSX.Element | null => {
	const { apiAccess } = useSanctum()
	const { anneeU, vet, "*": reste } = useParams() // paramètre de la route
	const params = reste ? reste.split("/") : []
	const [periode, module, matiere] = params
	const [formation, setFormation] = useState<FormationFlat_t | null | undefined>(null)

	const queryParams = [
		"annee=" + anneeU,
		"etape=" + vet,
		periode ? "periode=" + periode : null,
		module ? "module=" + module : null,
		matiere ? "matiere=" + matiere : null
	]
		.filter(Boolean)
		.join("&")

	const fetch = useCallback(async (): Promise<void> => {
		try {
			const reponse = await apiAccess.get<EcoleFlat_t>("/api/syllabus/arbre?" + queryParams)
			setFormation(reponse.data[0])
			console.log(reponse.data)
		} catch (error) {
			if (process.env.NODE_ENV === "development")
				console.error({ from: "Apogee.Formation.fetch", error })
		}
	}, [apiAccess, queryParams])

	// actif seulement au montage
	useEffect(() => {
		fetch()
	}, [fetch])

	const frm = formation?.parcours

	const header = !frm ? (
		<header>
			{/*<h1 className="text-center text-muted mb-4">Etape {vet}</h1>*/}
			<div>
				<h1 className="text-center text-muted mb-4">Imprimable</h1>
				<h2 className="text-center text-muted mb-4">Année Universitaire : {anneeU}</h2>
				<h2 className="text-center text-muted mb-4">VET : {vet}</h2>
				<h2 className="text-center text-muted mb-4">Période : {periode || "Non défini"}</h2>
				<h2 className="text-center text-muted mb-4">Module : {module || "Non défini"}</h2>
				<h2 className="text-center text-muted mb-4">Matière : {matiere || "Non défini"}</h2>
			</div>
		</header>
	) : (
		<header>
			<h1 className="text-center text-muted mb-4">
				Formation {frm.code} ({frm.nom ?? ""})
			</h1>
			<h3 className="text-center text-muted mb-4">
				<LigneBilingueFlat texte={frm.lic} langue={langue} />
			</h3>
			{frm.desc && (
				<div className="ms-2 me-auto">
					<span className="text-primary">Description&thinsp;: </span>
					<LigneBilingueFlat texte={frm.desc} langue={langue} />
				</div>
			)}
		</header>
	)

	const nbEtp = formation?.etapes.length
	const annee = anneeU && Number(anneeU)

	return (
		<>
			{header}
			{formation && nbEtp && annee && (
				<ContexteFormationFlat.Provider
					value={{
						annee: annee,
						diplome: formation?.diplome,
						parcours: formation?.parcours,
						langue: langue,
						blocsCompetence: formation?.blocsCompetence,
						niveauxCompetence: formation?.niveauxCompetence,
						modalitesEvalCompetence: formation?.modalitesEvalCompetence
					}}
				>
					<Container fluid>
						<h1>
							Année universitaire {annee} - {annee + 1}
						</h1>
						{Object.values(formation?.etapes).map((etp, ixe) => {
							return <ElpFlat element={etp} annee={annee} key={ixe} />
						})}
					</Container>
				</ContexteFormationFlat.Provider>
			)}
		</>
	)
}
