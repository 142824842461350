import React from "react"
import { useContext } from "react"
import { Col } from "react-bootstrap"
import { LigneBilingueFlat } from "../bilingue-flat"
import { ContexteFormationFlat } from "../imprimable"
import { CapaciteFlat_t } from "../api-syllabus-arbre"

interface Props {
	capacites: Array<CapaciteFlat_t>
}

/**
 * Capacites
 * 
 * Objet React de manipulation (affichage/édition) des capacités associées à un élément
 * 
 * @returns JSX.Element | null 
 */
export const CapacitesFlat = ( { capacites }: Props): JSX.Element | null => {

	const ctxt = useContext(ContexteFormationFlat)

	const lstItems = capacites?.map(
		(elt, ix) => {
			return (<li key={ix} className={ elt.validite === undefined ? "text-muted" : ""}>
				<sup style={ {color: "red"} }>{(elt.rangCapCom?.toLocaleString("fr-FR", {minimumIntegerDigits: 2}) ?? "##")} </sup>
				<LigneBilingueFlat texte={ elt.libCapacite } langue={ctxt.langue}/>
			</li>)
		}
	)

	return <Col xs={12} className="mx-0 mt-2 mb-1">
		<h3>Capacités</h3>
		<ul className="mb-0">{lstItems}</ul>
	</Col>
}
