import React, { useEffect } from "react"
import { useState } from "react"
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap"
import { LogIn, LogOut, User } from "react-feather"
import { useForm, FormProvider } from "react-hook-form"
import { OkCancel } from "../utilities/form-utilities"
import { useSanctum } from "./sanctum"

export interface Login_t {
	username: string
	password: string
	remember?: boolean
}

interface PropsCnxButton {
	onClick: () => void
	etiquette?: string
	spin?: boolean
}

const LogInButton = ({ onClick, etiquette, spin }: PropsCnxButton): JSX.Element => (
	<Button
		name="edit"
		variant="outline-success"
		size="sm"
		style={{ borderWidth: "4px" }}
		className="col-auto text-white"
		onClick={onClick}
	>
		<User color="white" size={18} alignmentBaseline="middle" />
		&nbsp; {etiquette ?? ""} &nbsp;
		{spin ? (
			<Spinner as="span" animation="border" variant="light" size="sm">
				<span className="visually-hidden">Loading...</span>
			</Spinner>
		) : (
			<LogIn color="white" size={18} alignmentBaseline="middle" />
		)}
	</Button>
)

const LogOutButton = ({ onClick, etiquette }: PropsCnxButton): JSX.Element => (
	<Button
		name="edit"
		variant="outline-danger"
		size="sm"
		style={{ borderWidth: "4px" }}
		className="col-auto text-white"
		onClick={onClick}
	>
		<User color="white" size={18} alignmentBaseline="middle" />
		&nbsp; {etiquette ?? ""} &nbsp;
		<LogOut color="white" size={18} alignmentBaseline="middle" />
	</Button>
)

export const Connexion = (): JSX.Element => {
	const { authState, signIn, signOut } = useSanctum()
	const [inForm, setInForm] = useState(false)
	const [outForm, setOutForm] = useState(false)
	const [spin, setSpin] = useState(false)

	// prettier-ignore
	const mkCallBack =
		(fn: (x: boolean) => void, valeur: boolean): (() => void) =>
			() => fn(valeur)

	useEffect(() => {
		if (authState.authenticated || authState.lasterror) setSpin(false)
	}, [authState])

	const FormLogin = () => {
		const methodes = useForm<Login_t>({
			mode: "onChange",
			defaultValues: {
				username: "",
				password: "",
				remember: true
			}
		})
		const { handleSubmit, register } = methodes
		const onSubmit = (value: Login_t) => {
			setInForm(false)
			setSpin(true)
			signIn(value)
		}
		const onclickCancel = mkCallBack(setInForm, false)
		return (
			<FormProvider {...methodes}>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<OkCancel valid={undefined} cancel={onclickCancel} />
					<Form.Group as={Row} controlId={"form_username"}>
						<Form.Label column sm={4} className="text-primary">
							Nom d&apos;utilisateur
						</Form.Label>
						<Col sm={8}>
							<Form.Control
								as="input"
								type="text"
								{...register("username", { required: true })}
							/>
						</Col>
						<Col>
							<Form.Text className="text-secondary">
								Votre nom d&apos;utilisateur Polytech.
							</Form.Text>
						</Col>
					</Form.Group>
					<Form.Group as={Row} controlId={"form_password"}>
						<Form.Label column sm={4} className="text-primary">
							Mot de passe
						</Form.Label>
						<Col sm={8}>
							<Form.Control
								as="input"
								type="password"
								{...register("password", { required: true })}
							/>
						</Col>
						<Col>
							<Form.Text className="text-secondary">
								Votre mot de passe Polytech.
							</Form.Text>
						</Col>
					</Form.Group>
					<OkCancel valid={undefined} cancel={onclickCancel} />
				</Form>
			</FormProvider>
		)
	}

	// prettier-ignore
	return authState.authenticated ? ( <>
		<LogOutButton
			etiquette={authState.user?.displayName ?? undefined}
			onClick={mkCallBack(setOutForm, true)}
		/>
		<Modal dialogClassName="modal-50w" show={outForm} keyboard={false} backdrop="static">
			<Modal.Header>
				<Modal.Title className="text-primary fs-5">
					Polytech Montpellier
					<br />
					Déconnexion {authState.user?.displayName}
					<br />
					Êtes-vous sûr(e) ?
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{outForm && (
					<OkCancel
						cancel={mkCallBack(setOutForm, false)}
						valid={() => {
							setOutForm(false)
							signOut()
						}}
					/>
				)}
			</Modal.Body>
		</Modal>
	</>	) : ( <>
		<LogInButton etiquette="Login" spin={spin} onClick={mkCallBack(setInForm, true)} />
		<Modal show={inForm} keyboard={false} backdrop="static">
			<Modal.Header>
				<Modal.Title className="text-primary">
					Polytech Montpellier
					<br />
					Connexion
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>{inForm && <FormLogin />}</Modal.Body>
		</Modal>
	</> )
}
