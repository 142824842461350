import React, { createContext, FunctionComponent, useContext, useEffect, useState } from "react"
import axios, { AxiosInstance, AxiosResponse } from "axios"
import axiosThrottle from "axios-request-throttle"
import { Login_t } from "./connexion"
import preval from "preval.macro"

export interface User_t {
	id?: number | null
	cn?: string | null
	samaccountname?: string | null
	givenName?: string | null
	sn?: string | null
	displayName?: string | null
	title?: string | null
	department?: string | null
	departmentNumber?: number | null
	mobile?: string | null
	mail?: string | null
	created_at?: Date
	updated_at?: Date
	domain?: string | null
	guid?: string | null
}

// Valeurs possibles pour le champ title:
// Etudiant, Permanent, Vacataire, Contractuel, Ater, UM,
// Assistance Info, Service, Visiteur, Doctorant, ATE, Stagiaire, Utilitaire,
// Hébergé (y compris avec un encodage bidon), incomplet, visiteur, APPRENTI,
// Heberge

// Une liste au 30 octobre 2022 est placée dans database-sagesse
// sous le nom DataApogee/PolytechUsers221003.xlsx

// les utilisateurs autorisés à modifier le yllabus sont listés dans le
// groupe applicatif "syllabus" (vérifié au niveau du backend)

interface SanctumProps {
	children: React.ReactNode
}

interface StateProps {
	user: null | User_t
	authenticated: undefined | null | boolean
	lasterror: undefined | string
}

type env_t = {
	base_url: string
	api_url: string
	infra: string
	node_env: string
	build_date: string
}
interface ContextInterface {
	authState: StateProps
	signIn: ({ username, password, remember }: Login_t) => void
	signOut: () => void
	errorAck: () => void
	apiAccess: AxiosInstance
	envExec: env_t
}

/**
 * env
 *
 * récupère l'url de la page courante pour construire une
 * description d'environnement. Deux cas seulement sont pris en compte:
 * - environnement de développement mac de GCath
 * - URL en www.sagesse... => environnement de production
 * 		toute autre => environnement de préprod
 *
 * @returns env_t
 */
const env = (): env_t => {
	const currenturl = new URL(window.location.href)
	const host = currenturl.hostname
	const port = currenturl.port
	const base = process.env.REACT_APP_BASE_URL ?? currenturl.protocol + "//" + host
	const dateTimeStamp: string = preval`module.exports = new Date().toLocaleString();`
	return {
		base_url: base + (port ? ":" + port : ""),
		api_url: process.env.REACT_APP_API_URL ?? base,
		infra:
			process.env.REACT_APP_INFRA ??
			(host.startsWith("www.sagesse") ? "Production syllabus" : "Bac à sable"),
		node_env: process.env.NODE_ENV,
		build_date: dateTimeStamp
	}
}

const envExec = env()
const apiUrl = envExec.api_url
const csrfCookieRoute = "/sanctum/csrf-cookie"
const signInRoute = "/login"
const signOutRoute = "/logout"
const userObjectRoute = "/api/user"

// Configuration d'axiosThrottle avec la valeur initiale de throttle
axiosThrottle.use(axios, { requestsPerSecond: 2 })

const instanceAxios = axios.create({
	baseURL: apiUrl,
	withCredentials: true,
	withXSRFToken: true,
	maxRedirects: 0,
	headers: {
		"X-Requested-With": "XMLHttpRequest",
		Accept: "application/json"
	}
})

instanceAxios.interceptors.request.use(async (config) => {
	const methodes = /put|patch|post|delete/
	if (
		config.method !== undefined &&
		methodes.test(config.method) &&
		!/^(.*;)?\s*XSRF-TOKEN\s*=/.test(document.cookie)
	) {
		await instanceAxios.get(csrfCookieRoute)
	}
	return config
})

const updateThrottle = (response: AxiosResponse<unknown, unknown>): void => {
	const headers = response.headers
	const rateLimitRemaining = parseInt(headers["x-ratelimit-remaining"], 10)
	const rateLimitLimit = parseInt(headers["x-ratelimit-limit"], 10)
	const retryAfter = parseInt(headers["retry-after"], 10)

	// Ajuste le débit si des en-têtes de limitation sont présents
	if (rateLimitLimit && rateLimitRemaining !== undefined) {
		const requestsPerMinute = rateLimitLimit / (retryAfter ? retryAfter / 60 : 1)
		const requestsPerSecond = requestsPerMinute / 60 // Convertir par minute en par seconde

		// Reconfigurer axiosThrottle si la limite change
		axiosThrottle.use(axios, { requestsPerSecond })

		console.log(`Ajustement du débit : ${requestsPerSecond.toFixed(2)} requêtes/s`)
	}
}

// Intercepteur pour capturer les en-têtes des réponses
instanceAxios.interceptors.response.use(
	(response) => {
		updateThrottle(response)
		return response
	},
	async (error) => {
		if (error.response?.status === 429) {
			const retryAfter = parseInt(error.response.headers["retry-after"], 10)
			if (retryAfter) {
				console.warn(
					`Limite atteinte. Attente de ${retryAfter} secondes avant de réessayer.`
				)
				await new Promise((resolve) => setTimeout(resolve, retryAfter * 1000))
			}
		}
		return Promise.reject(error)
	}
)

const SanctumContext = createContext<ContextInterface | null>(null)

export const Sanctum: FunctionComponent<SanctumProps> = ({ children }) => {
	const [sanctumState, setSanctumState] = useState<StateProps>({
		user: null,
		authenticated: undefined,
		lasterror: undefined
	})

	const errorCatch = (erreur: unknown, prefix?: string) => {
		if (axios.isAxiosError(erreur)) {
			const reponse = erreur.response
			let message = erreur.code + " = " + erreur.message
			if (reponse !== undefined) {
				message = reponse.status + " = " + reponse.statusText
			}
			if (prefix) message = prefix + message
			setSanctumState({ ...sanctumState, lasterror: message })
		} else {
			console.log({ type: "inconnu", erreur })
		}
	}

	const signIn = async ({ username, password, remember = false }: Login_t) => {
		try {
			await instanceAxios.post<Record<string, unknown>>(signInRoute, {
				username,
				password,
				remember
			})
			const userData = await instanceAxios.get<User_t>(userObjectRoute)
			setSanctumState({
				user: userData.data,
				authenticated: true,
				lasterror: undefined
			})
			// console.log({ user: userData.data })
		} catch (erreur) {
			errorCatch(erreur, username + " : erreur ")
		}
	}

	const signOut = async () => {
		try {
			await instanceAxios.post<Record<string, unknown>>(signOutRoute)
			setSanctumState({
				user: null,
				authenticated: false,
				lasterror: undefined
			})
		} catch (erreur) {
			errorCatch(erreur)
		}
	}

	const errorAck = () => setSanctumState({ ...sanctumState, lasterror: undefined })

	const checkAuth = async () => {
		try {
			const userData = await instanceAxios.get<User_t>(userObjectRoute)
			setSanctumState({
				user: userData.data,
				authenticated: true,
				lasterror: undefined
			})
		} catch (erreur) {
			if (axios.isAxiosError(erreur)) {
				if (erreur.response?.status !== 401) console.log({ type: "checkAuth", erreur })
				setSanctumState({
					user: null,
					authenticated: false,
					lasterror: undefined
				})
			} else {
				console.log({ type: "inconnu", erreur })
			}
		}
	}

	// n'est actif qu'au montage
	useEffect(() => {
		if (sanctumState.authenticated === undefined) {
			setSanctumState({ ...sanctumState, authenticated: false })
			checkAuth()
		}
	}, [sanctumState])

	return (
		<SanctumContext.Provider
			value={{
				authState: sanctumState,
				signIn,
				signOut,
				errorAck,
				apiAccess: instanceAxios,
				envExec: envExec
			}}
		>
			{children}
		</SanctumContext.Provider>
	)
}

export const useSanctum = (): ContextInterface => {
	const context = useContext(SanctumContext)
	if (!context) throw new Error("useSanctum should only be used inside <Sanctum />")
	return context
}

export default Sanctum
