import React, { useCallback, useEffect, useState } from "react"
import { Row, Col } from "react-bootstrap"
import { Bilingue_t } from "../utilities/bilingue"
import { useSanctum } from "../sanctum/sanctum"
import { Specialite } from "./specialite"

// Parcours et Formation sont des synonymes...
// L'objer de base de données est un Parcours
// les éléments React correspondants sont des Formations
// ou des Specialites

/**
 * Parcours_t
 *
 * Objet de l'API issu de
 * base de données, table sagesse.parcours
 * backend-sagesse/app/Modeles/Sagesse/Parcours.php
 * alternative:
 * backend-sagesse/app/Http/Controllers/SagesseController.php (fonction ecole)
 */
export interface Parcours_t {
	codParcours: string
	nomParcours: string | null
	licParcours: string | null
	licParcours_en: string | null
	descParcours: Bilingue_t | null
}

/**
 * LstParcours_t
 *
 * Objet de l'API issu de
 * base de données, table sagesse.parcours, via
 * backend-sagesse/app/Http/Controllers/SagesseController.php (fonction ecole)
 */
interface LstParcours_t {
	[codParcours: string]: Parcours_t
}

export const PortailEcole = (): JSX.Element | null => {
	const { apiAccess } = useSanctum()
	const [parcours, setParcours] = useState<LstParcours_t | null>(null)

	const fetch = useCallback(async () => {
		try {
			const reponse = await apiAccess.get<LstParcours_t>("/api/ecole")
			// console.log( { reponse })
			setParcours(reponse.data)
		} catch (error) {
			if (process.env.NODE_ENV === "development")
				console.error({ from: "Ecole.PortailEcole.fetch", error })
		}
	}, [apiAccess])

	// le test sur "parcours" est-il absolument nécessaire ?
	// useEffect(() => { if (parcours === null && authState.authenticated) fetch() }, [authState])
	// simplification: toutes les données sont publiques
	useEffect(() => {
		fetch()
	}, [fetch])

	// un design plus sympathique de cette page pourra être obtenu
	// en utilisant des <CardGroup>. Ceux-ci n'étant pas "responsive",
	// il faudra gérer la répartition des spécialités dans
	// les différentes lignes.
	// Cf. article https://dev.to/franciscomendes10866/how-to-make-your-react-apps-responsive-with-a-custom-hook-4hd3
	// pour la façon d'écrire ce code.

	return (
		<>
			<h1 className="text-center text-muted mb-4">
				Formations de l&apos;école
			</h1>
			{parcours && (
				<Row>
					{Object.entries(parcours).map((prc) => (
						<Col key={prc[0]} xs={12} sm={6} md={6} lg={4} xl={3}>
							<Specialite prc={prc[1]} />
						</Col>
					))}
				</Row>
			)}
		</>
	)
}
