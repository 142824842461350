import React from "react"
import { useContext } from "react"
import { Col } from "react-bootstrap"
import { LigneBilingueFlat } from "../bilingue-flat"
import { ContexteFormationFlat } from "../imprimable"
import { MotClefFlat_t } from "../api-syllabus-arbre"

interface Props {
	motsclefs: Array<MotClefFlat_t>
}

/**
 * MotsClefsFlat
 * 
 * Objet React de manipulation (affichage/édition) des mots clefs associés à un élément
 * 
 * @returns JSX.Element | null 
 */
export const MotsClefsFlat = ( { motsclefs }: Props): JSX.Element | null => {

	const ctxt = useContext(ContexteFormationFlat)

	const lstItems = motsclefs?.map(
		(elt, ix) => {
			return (<li key={ix} className={ elt.validite === undefined ? "text-muted" : ""}>
				<LigneBilingueFlat texte={ elt.texteMC } langue={ctxt.langue}/>
			</li>)
		}
	)

	return <Col xs={12} className="mx-0 mt-2 mb-1">
		<h3>Mots clés</h3>
		<ul className="mb-0">{lstItems}</ul>
	</Col>
}
