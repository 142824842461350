import React from "react"
import { Card } from "react-bootstrap"
import { ElpFlat } from "./elp-flat"
import { ListeFlat_t } from "./api-syllabus-arbre"

interface PropsListe {
	annee: number
	listes: Array<ListeFlat_t>
}

interface PropsHeader {
	lst: ListeFlat_t
}

// prettier-ignore
const ListHeader = ({ lst }: PropsHeader) => {
	switch (lst.typLse) {
		case "O" : return (
			<Card.Header>
				{lst.codLse}: Liste obligatoire
			</Card.Header>
		)
		case "X" : return (
			<Card.Header>
				{lst.codLse}: Liste obligatoire à choix, sélectionner {
					(lst.nbMinElp === lst.nbMaxElp)
						? "exactement " + lst.nbMinElp + ((lst.nbMinElp < 2) ? " élément " : " éléments ")
						: "de " + lst.nbMinElp + " à " +  lst.nbMaxElp + " éléments "
				} de premier niveau dans la liste.
			</Card.Header>
		)
	}
	return (
		<Card.Header>
			{lst.codLse}: Liste d&apos;éléments facultatifs
		</Card.Header>
	)
}

export const ListesAvecTabsFlat = ({
	listes,
	annee
}: PropsListe): JSX.Element => (
	<>
		{listes.map((lst) => (
			<Card key={lst.codLse} className="mt-1">
				<ListHeader lst={lst} />
				<Card.Body className="pt-2">
					{lst.elements.map((elp) => (
						<ElpFlat annee={annee} element={elp} key={elp.code} />
					))}
				</Card.Body>
			</Card>
		))}
	</>
)

export const ListesSansTabsFlat = ({
	listes,
	annee
}: PropsListe): JSX.Element => (
	<>
		{listes.map((lst) =>
			lst.elements.map((elp) => (
				<ElpFlat
					annee={annee}
					element={elp}
					key={lst.codLse + "-" + elp.code}
				/>
			))
		)}
	</>
)
