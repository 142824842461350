import React from "react"
import { AcquisApprentissageFlat } from "./syllabus/acquis-apprentissage-flat"
import { CapacitesFlat } from "./syllabus/capacites-flat"
import { ChargesFlat } from "./syllabus/charges-flat"
// import { CompetencesFlat } from "./syllabus/competences-flat"
import { FactuelFlat } from "./syllabus/factuel-flat"
import { MotsClefsFlat } from "./syllabus/mots-clefs-flat"
import { NoticeFlat } from "./syllabus/notice-flat"
import { ElementFlat_t } from "./api-syllabus-arbre"


export interface DetailsContextFlat_t {
	url: string
	annee: number
	code: string
}

interface Props {
	element: ElementFlat_t
}

export const DetailsFlat = ({ element }: Props): JSX.Element | null => {

	if ((element.charges ?? element.details ?? null) == null) return null

	return /*<Col className="px-0">*/<>
		{element.charges && <ChargesFlat charges={element.charges} />}
		{element.details?.notice && <NoticeFlat  notice={element.details.notice} />}
		<FactuelFlat element={element} />
		{element.details?.capacites && <CapacitesFlat capacites={element.details.capacites} />}
		{element.details?.motsClefs && <MotsClefsFlat motsclefs={element.details.motsClefs} />}
		{element.details?.acquisApp && <AcquisApprentissageFlat acquisapp={element.details.acquisApp} />}
		{/* <CompetencesFlat /> */}</>
	//</Col>
}
